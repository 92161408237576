
  
  .map-iframe {
    width: 100%;
    height: auto;
  }
  
  /* Responsive styles */
  .map-iframe iframe {
    width: 100%;
    height: 300px; /* Adjust the height as needed */
  }
  
  @media (min-width: 768px) {
    .map-iframe iframe {
      height: 400px; /* Adjust the height as needed */
    }
  }
  
  @media (min-width: 992px) {
    .map-iframe iframe {
      height: 430px; /* Adjust the height as needed */
    }
  }
  