
/* Logo Styling */
.navbar-brand img {
    max-height: 65px; /* Controls the maximum height of the logo */
    width: 130px;
    margin-right: 8vh;
    border-radius: 8px; /* Keeps the width in proportion to the height */
    transition: transform 0.3s ease, filter 0.3s ease; /* Adds smooth transition effects */
  }
  
  /* Hover Effect */
  .navbar-brand img:hover {
    transform: scale(1.1); /* Slightly enlarges the logo on hover */
    filter: brightness(1.2); /* Makes the logo slightly brighter on hover */
  }
  
  /* For High-Resolution Screens */
  @media only screen and (min-width: 768px) {
    .navbar-brand img {
      max-height: 80px; /* Increase size for larger screens */
    }
  }
  
  /* Responsive Logo Padding */
  .navbar-brand {
    padding: 10px 15px; /* Adds space around the logo */
    display: flex;
    align-items: center; /* Vertically centers the logo */
  }
  
  


















  
  