.img-fluid {
    width: 100%;
    height: auto;
  }
  
  .img-thumb img {
    width: 100%;
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area */
  }

  .title-hero-19 {
    white-space: nowrap; /* Prevent text from wrapping */
    text-align: center; /* Center align the text if needed */
    width: 100%; /* Ensure it uses full width of the container */
    margin: 0;
    
    margin-left: -10vh;
}

.y{
  white-space: nowrap; /* Prevent text from wrapping */
    text-align: center; /* Center align the text if needed */
    width: 100%; /* Ensure it uses full width of the container */
    margin: 0;
  
    margin-left: 55vh;
    font-size: 1.6rem !important ;
}
.S{
  
  color: aliceblue !important ;
  white-space: nowrap; /* Prevent text from wrapping */
    text-align: center; /* Center align the text if needed */
    width: 100%; /* Ensure it uses full width of the container */
    margin: 0;
    margin-left: 55vh;
    font-size: 1.4rem !important ;
}
@media (max-width: 768px) {
  .title-hero-19{
    font-size: 1.1rem !important;
    margin-left: 1vh !important;

  }
  
  
  .y{
    font-size: 0.9rem !important;
    margin-left: 1vh; /* Remove margin-left to center text properly */
    padding: 0 10px;
    margin-bottom: 20px !important;
    
  }
  
  
  
  .S {
    font-size: 0.8rem !important; /* Reduce font size on small screens */
    margin-left: 1vh; /* Remove margin-left to center text properly */
    padding: 0 10px;
    margin-bottom: -10px;/* Add some padding to prevent text from touching the edges */
  }
}




@media (max-width: 768px) {
  .w3l-video-sec, .w3video-bghny, .history-info {
    padding: 2rem 0; /* Reduce padding for mobile screens */
  }

  .play-view {
    font-size: 2rem; /* Reduce the size of the play icon */
  }

  video {
    width: 100%;
    max-width: 100%; /* Ensure the video takes up full width on mobile */
    height: auto; /* Keep the aspect ratio */
  }
}



.advanced-gallery {
  background: linear-gradient(90deg, #dfd9d9, #0b0a0a, #f0e5e5, #837777);


  padding: 10px 0;
  margin-bottom: 100px;
  height: 100vh;
  
}

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -15vh
}

.title-content {
  margin-bottom: 50px;
}

.gallery-subtitle {
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
}

.gallery-title {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
}

/* Gallery grid */
.advanced-gallery-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.4s ease;
  flex: 0 0 calc(33.33% - 30px); /* For 3 images per row on large screens */
  margin-bottom: 30px;
 
}

.gallery-item:hover {
  transform: translateY(-5px);
}

.image-wrapper {
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  transition: all 0.5s ease;
  width: 100%;
  height: 25vh;
  object-fit: cover;
  border-radius: 15px;
}

/* Hover effect */
.gallery-item:hover .gallery-image {
  transform: scale(1.1);
  filter: brightness(0.7);
}

.gallery-item:hover .image-wrapper:after {
  content: "Before After Image";
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 5px;
  opacity: 1;
  transition: all 0.3s ease;
}

.gallery-item .image-wrapper:after {
  content: "";
  opacity: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

/* Responsive adjustments for mobile and tablet screens */
@media (max-width: 992px) {
  .gallery-item {
    flex: 0 0 calc(50% - 20px); /* For 2 images per row on tablets */
  }

  .gallery-title {
    font-size: 32px;
  }

  .gallery-item:hover .image-wrapper:after {
    font-size: 18px;
  
  }
}

@media (max-width: 776px) {
  .gallery-item {
    flex: 0 0 calc(30% - -12.5px); /* For 2 images per row on mobile */
   
  }

  .gallery-title {
    font-size: 24px;
  }

  .gallery-item:hover .image-wrapper:after {
    font-size: 14px;
   
  }
  .gallery-image {
    transition: all 0.5s ease;
    width: 60vw !important;
    height: 20vh;
    object-fit: cover;
    border-radius: 15px;
   
  }
  
  
}
